<template>
  <base-layout page-title="Validate" :showBackButton="true">
  <ion-row class="ion-no-padding ion-no-pargin">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item
          no-lines
          lines="none"
          class="
            ion-justify-content-start
            ion-align-items-baseline
            ion-no-padding ion-no-pargin
          "
        >
          <ion-avatar
            style="cursor: pointer; position: absolute; margin-top: 20px;"
            @click="navigateUserProfile(user.tokenized_user)"
            class="left-margin-15px"
          >
            <ion-icon size="large" :icon="callOutline"></ion-icon>
          </ion-avatar>
          <span style="padding-left: 60px; margin-top: 20px;">
            <ion-label
              style="cursor: pointer; padding-bottom: 20px;"
            >
              <b>{{ phoneVerifiedStatus.value ? phoneVerifiedStatus.value : 'Not available' }}</b><br/>
              <small v-if="phoneVerifiedStatus.verified">{{ getStrings(strings, "Verified on") }} {{ formatDate(phoneVerifiedStatus.verified_on) }}</small>
              <small v-if="!phoneVerifiedStatus.verified">{{ getStrings(strings, "Not Verified") }}</small>
            </ion-label>
            <ion-row class="ion-no-padding ion-no-margin" v-if="phoneChangeVisible">
              <ion-col class="ion-no-padding ion-no-margin" style="padding-left: 0px;">
                <ion-label v-if="!OTPSent" style="padding-bottom: 10px;">Phone to change/validate</ion-label>
                <ion-item v-if="!OTPSent" no-lines lines="none" class="ion-no-padding ion-no-margin">
                  <ion-select
                    class="ion-no-padding ion-no-margin"
                    v-model="country_code"
                    required
                    v-if="!OTPSent"
                  >
                    <ion-select-option
                      v-for="country in countryNames"
                      v-bind:key="country.tel_code"
                      :value="country.tel_code"
                      class="ion-no-padding ion-no-margin"
                      no-lines
                      lines="none"
                      >{{ '+' + country.tel_code + ' ' + country.name }}</ion-select-option>
                  </ion-select>
                  </ion-item>
                  <ion-item no-lines lines="none" v-if="!OTPSent" class="signup-item-style ion-no-padding ion-no-margin">
                    <ion-input
                      v-model="phone"
                      id="phone"
                      ref="phone"
                      type="text"
                      placeholder="Phone Number"
                      required
                    ></ion-input>
                  </ion-item>
                <ion-button
                  v-if="!OTPSent"
                  @click="sendOTPToVerify('phone')"
                  :disabled="sendOTPClicked || !phone || (phone && phone.length < 1)"
                >
                  Send OTP
                </ion-button>
                <ion-label v-if="OTPSent">Enter OTP to validate</ion-label>
                <ion-item no-lines lines="none" class="signup-item-style ion-no-padding ion-no-margin" v-if="OTPSent">
                  <ion-input
                    v-model="receivedOTP"
                    id="receivedOTP"
                    ref="receivedOTP"
                    type="text"
                    placeholder="OTP"
                    v-if="OTPSent"
                    required
                  ></ion-input>
                </ion-item>
                <ion-button
                  v-if="OTPSent"
                  :disabled="validateOTPClicked || !receivedOTP || (receivedOTP && receivedOTP.length < 1)"
                  @click="onValidateOTP('phone')"
                >
                  Validate OTP
                </ion-button>
                 <ion-label v-if="OTPSent" color="success" class="ion-text-wrap">
                  <small>OTP sent to your phone. Please check SMS and enter correct OTP</small>
                </ion-label>
              </ion-col>
            </ion-row>
          </span>
          <ion-icon
            slot="end"
            style="cursor: pointer"
            class="right-margin-15px"
            @click="phoneExpandClicked"
            :icon="chevronDownOutline"
            v-if="!phoneChangeVisible"
          ></ion-icon>
          <ion-icon
            slot="end"
            class="right-margin-15px"
            @click="collapsedClicked"
            :icon="chevronUpOutline"
            v-if="phoneChangeVisible"
          ></ion-icon>
        </ion-item>
        <ion-item
          no-lines
          lines="none"
          class="
            ion-justify-content-start
            ion-align-items-baseline
          "
        >
          <ion-avatar
            style="cursor: pointer; position: absolute; margin-top: 20px;"
            @click="navigateUserProfile(user.tokenized_user)"
            class="left-margin-15px"
          >
            <ion-icon size="large" :icon="mailOutline"></ion-icon>
          </ion-avatar>
          <span style="padding-left: 60px; margin-top: 20px;">
            <ion-label
              style="cursor: pointer; padding-bottom: 20px;"
            >
              <b>{{ emailVerifiedStatus.value ? emailVerifiedStatus.value : 'Not available' }}</b><br/>
              <small v-if="emailVerifiedStatus.verified">{{ getStrings(strings, "Verified on") }} {{ formatDate(emailVerifiedStatus.verified_on) }}</small>
              <small v-if="!emailVerifiedStatus.verified">{{ getStrings(strings, "Not Verified") }}</small>
            </ion-label>
            <ion-row class="ion-no-margin ion-no-padding" v-if="emailChangeVisible">
              <ion-col class="ion-no-margin" style="padding-left: 0px;">
                <ion-label v-if="!OTPSent" style="padding-bottom: 10px;">Email to change/validate</ion-label>
                  <ion-item no-lines lines="none" v-if="!OTPSent" class="signup-item-style ion-no-padding ion-no-margin">
                    <ion-input
                      v-model="email"
                      id="email"
                      ref="email"
                      type="email"
                      placeholder="email"
                      required
                    ></ion-input>
                  </ion-item>
                <ion-button
                  v-if="!OTPSent"
                  @click="sendOTPToVerify('email')"
                  :disabled="sendOTPClicked || !email || (email && email.length < 1)"
                >
                  Send OTP
                </ion-button>
                <ion-label v-if="OTPSent">Enter OTP to validate</ion-label>
                <ion-item no-lines lines="none" class="signup-item-style ion-no-padding ion-no-margin" v-if="OTPSent">
                  <ion-input
                    v-model="receivedOTP"
                    id="receivedOTP"
                    ref="receivedOTP"
                    type="text"
                    placeholder="OTP"
                    v-if="OTPSent"
                    required
                  ></ion-input>
                </ion-item>
                <ion-button
                  v-if="OTPSent"
                  :disabled="validateOTPClicked || !receivedOTP || (receivedOTP && receivedOTP.length < 1)"
                  @click="onValidateOTP('email')"
                >
                  Validate OTP
                </ion-button>
                <ion-label v-if="OTPSent" color="success" class="ion-text-wrap">
                  <small>OTP sent to email. Please check your email and enter correct OTP</small>
                </ion-label>
              </ion-col>
            </ion-row>
          </span>
          <ion-icon
            slot="end"
            class="right-margin-15px"
            @click="emailExpandClicked"
            :icon="chevronDownOutline"
            v-if="!emailChangeVisible"
          ></ion-icon>
          <ion-icon
            slot="end"
            class="right-margin-15px"
            @click="collapsedClicked"
            :icon="chevronUpOutline"
            v-if="emailChangeVisible"
          ></ion-icon>
        </ion-item>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import { getColSize, getOffsetSize, formatDate } from "../services/utils";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonAvatar,
  IonIcon,
  IonInput,
  IonRow,
  IonCol,
  alertController,
  //IonSpinner,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";

import {
  callOutline,
  mailOutline,
  chevronDownOutline,
  chevronUpOutline,
} from "ionicons/icons";

import { getUserVerifiedStatus, sendOTP, verifyOTP } from "../services/apiCall";
import { useRouter } from "vue-router";
import { readFromDB, clearObjectStore, closeDB } from "../services/db.js";
import { getStrings } from "../services/lang";
import { countryList } from "../services/countries.js";

export default {
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonAvatar,
    IonIcon,
    IonInput,
    IonRow,
    IonCol,
    //IonSpinner,
    IonSelect,
    IonSelectOption,
  },
  ionViewWillEnter() {
    const callbackObject = this;
    readFromDB(this.$store)().then(async () => {
      // read from DB done
      if (
        typeof callbackObject.user === "undefined" ||
        typeof callbackObject.user.user_id === "undefined"
      ) {
        await clearObjectStore();
        await closeDB();
        callbackObject.router.replace("/login");
      }
    });
    this.getVerifiedStatus();
  },
  setup() {
    const router = useRouter();

    return {
      router,
      getColSize,
      getOffsetSize,
      getStrings,
      countryList,
      formatDate,
    };
  },
  data() {
    return {
      getUserVerifiedStatus,
      sendOTP,
      verifyOTP,
      callOutline,
      mailOutline,
      chevronDownOutline,
      chevronUpOutline,
      receivedOTP: "",
      phoneOTP: "",
      emailOTP: "",
      countryNames: this.countryList(),
      country: "India",
      country_code: "91",
      phone: "",
      email: "",
      phoneChangeVisible: false,
      emailChangeVisible: false,
      validateOTPClicked: false,
      sendOTPClicked: false,
      OTPSent: false,
      phoneVerifiedStatus: {},
      emailVerifiedStatus: {},
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async showMessage(title, msg) {
      const failedAlert = await alertController.create({
        header: title,
        message: msg,
        buttons: ["OK"],
      });
      await failedAlert.present();
    },
    getVerifiedStatus() {
      getUserVerifiedStatus()
        .then(async (res) => {
          if (res.data.status === 0) {
            var verifiedStatus = res.data.result.verified_status;
            this.phoneVerifiedStatus = verifiedStatus.filter((y) => y.type === 'phone');
            if (this.phoneVerifiedStatus.length > 0) {
              this.phoneVerifiedStatus = this.phoneVerifiedStatus[0];
              this.phone = this.phoneVerifiedStatus.value;
            } else {
              this.phoneVerifiedStatus = {};
            }

            this.emailVerifiedStatus = verifiedStatus.filter((y) => y.type === 'email');
            if (this.emailVerifiedStatus.length > 0) {
              this.emailVerifiedStatus = this.emailVerifiedStatus[0];
              this.email = this.emailVerifiedStatus.value;
            } else {
              this.emailVerifiedStatus = {};
            }

          } else {
            console.log("getUserVerifiedStatus - err1");
          }
        })
        .catch(async (err) => {
          console.log("getUserVerifiedStatus - err2", err);
        });
    },
    sendOTPToVerify(otpType) {
      this.sendOTPClicked = true;
      var options = {'otp_type': otpType }
      if (otpType === 'phone') {
        options = {'otp_type': otpType, 'send_using': this.phone, 'country_code': this.countryCode };
      } else if (otpType === 'email') {
        options = {'otp_type': otpType, 'send_using': this.email };
      }
      sendOTP(options)
        .then((res) => {
          if (res.data.status === 0) {
            this.OTPSent = true;
            this.sendOTPClicked = false;
          } else {
            this.showMessage('OTP Error', res.data.error.error_message);
            console.log("sendOTPToVerify - err1");
            this.sendOTPClicked = false;
          }
        })
        .catch((err) => {
          this.sendOTPClicked = false;
          console.log("sendOTPToVerify - err2", err);
        });
    },
    onValidateOTP(otpType) {
      this.validateOTPClicked = true;
      var options = {'otp_type': otpType, 'otp': this.receivedOTP, 'update_value': true };
      verifyOTP(options)
        .then((res) => {
          if (res.data.status === 0) {
            this.sendOTPClicked = false;
            this.validateOTPClicked = false;
            this.receivedOTP = "";
            if (res.data.result.verify_otp.is_verified) {
              if (otpType === 'phone') {
                this.phoneVerifiedStatus.verified = res.data.result.verify_otp.is_verified;
                this.phoneVerifiedStatus.value = res.data.result.verify_otp.value;
                this.phoneVerifiedStatus.verified_on = res.data.result.verify_otp.verified_on;
                this.showMessage('Verified', "Phone is verified and updated.\nPlease use this number for login next time.");
              } else if (otpType === 'email') {
                this.emailVerifiedStatus.verified = res.data.result.verify_otp.is_verified;
                this.emailVerifiedStatus.value = res.data.result.verify_otp.value;
                this.emailVerifiedStatus.verified_on = res.data.result.verify_otp.verified_on;
                this.showMessage('Verified', "Email is verified and updated.\nPlease use this email for login next time.");
              }
              this.collapsedClicked();
              this.OTPSent = false;
            }
          } else {
            this.showMessage('OTP Error', res.data.error.error_message);
            this.validateOTPClicked = false;
          }
        })
        .catch((err) => {
          this.validateOTPClicked = false;
          console.log("onValidateOTP - err2", err);
        });
    },
    phoneExpandClicked() {
      this.emailChangeVisible = false;
      this.phoneChangeVisible = true;
      this.OTPSent = false;
    },
    emailExpandClicked() {
      this.emailChangeVisible = true;
      this.phoneChangeVisible = false;
      this.OTPSent = false;
    },
    collapsedClicked() {
      this.emailChangeVisible = false;
      this.phoneChangeVisible = false;
      this.OTPSent = false;
    },

  },
};
</script>